<template>
  <div class="homePage">
    <div class="app h5MaxWidth">
      <div class="main">
        <div class="logoBar h5MaxWidth">
          <div class="display alignCenter justifyDetween">
            <div class="logo flex1">
              <img src="./../images/logo_w.svg" alt="" />
            </div>
          </div>
        </div>

        <div class="banner h5MaxWidth radius003030PX">
          <div class="owl-carousel owl-theme">
            <van-swipe
              class="my-swipe"
              :height="500"
              :autoplay="3000"
              indicator-color="white"
            >
              <van-swipe-item>
                <div class="item radius7PX">
                  <img src="./../images/01.png" />
                </div>
              </van-swipe-item>
              <van-swipe-item>
                <div class="item radius7PX">
                  <img src="./../images/02.png" />
                </div>
              </van-swipe-item>
              <van-swipe-item>
                <div class="item radius7PX">
                  <img src="./../images/03.png" />
                </div>
              </van-swipe-item>
            </van-swipe>
          </div>
        </div>

        <div class="kongArea">
          <div class="gongGeNAV">
            <ul class="display alignEnd alignStretch">
              <li
                class="flex1 display justifyCenter alignEnd radius15PX"
                @click="goPage('/invite')"
              >
                <div>
                  <div class="icon">
                    <img src="./../images/Rewards.png" alt="" />
                  </div>
                  <p>Invite</p>
                </div>
              </li>
              <li
                class="flex1 display justifyCenter alignEnd radius15PX"
                @click="goPage('/taskHall')"
              >
                <div>
                  <div class="icon">
                    <img src="./../images/gold.png" alt="" />
                  </div>
                  <p>TaskHall</p>
                </div>
              </li>
              <li
                class="flex1 display justifyCenter alignEnd radius15PX"
                @click="goPage('/orders')"
              >
                <div>
                  <div class="icon">
                    <img src="./../images/FinancialOrder.png" alt="" />
                  </div>
                  <p>MyOrder</p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="financialList radius15PX">
          <div class="ivestList">
            <div class="swiper-container tagNavBar" id="tagNavBar">
              <div class="swiper-wrapper tagNav-list">
                <div
                  :class="
                    tab === 0
                      ? 'swiper-slide tagNav tag_1 selected'
                      : 'swiper-slide tagNav tag_1'
                  "
                  @click="changeTab(0)"
                  navid="1"
                >
                  <div class="radius151500PX">
                    <p>Monthly</p>
                  </div>
                </div>
                <div
                  :class="
                    tab === 1
                      ? 'swiper-slide tagNav tag_2 selected'
                      : 'swiper-slide tagNav tag_2'
                  "
                  @click="changeTab(1)"
                  navid="2"
                >
                  <div class="radius151500PX">
                    <p>Daily</p>
                  </div>
                </div>
                <div
                  :class="
                    tab === 2
                      ? 'swiper-slide tagNav tag_3 selected'
                      : 'swiper-slide tagNav tag_3'
                  "
                  @click="changeTab(2)"
                  navid="3"
                >
                  <div class="radius151500PX">
                    <p>Activity</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- / -->
            <div id="typeList" class="typeList">
              <div class="productList activate_list" id="typeList1">
                <div class="list">
                  <ul class="flex1">
                    <li
                      v-for="item in dataList"
                      :key="item.id"
                      class="display alignStart alignStretch radius15PX"
                      @click="buyNow(item)"
                    >
                      <div class="sketch">
                        <img :src="item.img" alt="">
                        <!-- <img src="./../images/101.png" alt="" v-if="tab == 0" />
                        <img src="./../images/102.png" alt="" v-if="tab == 1" />
                        <img src="./../images/103.png" alt="" v-if="tab == 2" /> -->
                      </div>
                      <div class="eachPrice flex1">
                        <div class="marked radius7PX">
                          <ol>
                            <li class="flex1">
                              <h3>
                                <em class="currency">₹</em>{{ item.price }}
                              </h3>
                              <span>Each Price</span>
                            </li>
                          </ol>
                        </div>
                        <div
                          class="buyStatusBtn clickBtn_S display alignCenter justifyCenter"
                        >
                          <div class="display alignCenter justifyCenter">
                            <div
                              class="requiredLV vipLevelMark display alignCenter justifyCenter radius500PX"
                              id="vip"
                            >
                              <div class="vip_ICON radius50P">
                                <img src="./../images/lv0.svg" alt="" />
                              </div>
                            </div>
                            <p>Buy Now</p>
                          </div>
                          <img
                            class="upcastArrow"
                            src="./../images/UpcastArrow.png"
                            alt=""
                          />
                        </div>
                        <div class="attribute">
                          <ol>
                            <li class="flex1">
                              <div class="display alignCenter">
                                <div class="title">
                                  <span>Revenue</span>
                                </div>
                                <div class="item">
                                  <p>{{ item.period }} days</p>
                                </div>
                              </div>
                            </li>
                            <li class="flex1">
                              <div class="display alignCenter">
                                <div class="title">
                                  <span>Daily Income</span>
                                </div>
                                <div class="item">
                                  <p>
                                    <em class="currency">₹</em
                                    >{{ shouyi(item) }}
                                  </p>
                                </div>
                              </div>
                            </li>

                            <li class="flex1">
                              <div class="display alignCenter">
                                <div class="title">
                                  <span>Total Revenue</span>
                                </div>
                                <div class="item">
                                  <p>
                                    <em class="currency">₹</em
                                    >{{ shouyiAll(item) }}
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ol>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="showPop" position="top" :style="{ background: 'none' }">
      <div class="promptMain et-02s radius15PX" style="margin-top: 40%">
        <div class="promptTitleBar display alignCenter justifyDetween"></div>
        <div class="promptCont">
          <div class="reconfirmPurchase">
            <div class="instructions">
              <p style="font-size: 18px">
                Are you sure you want to invest this product?
              </p>
            </div>
          </div>
          <div
            class="actionBar display alignCenter alignStretch"
            style="margin-top: 40px"
          >
            <div
              class="cancelBtn clickBtn_S flex1 display alignCenter justifyCenter"
              @click="showPop = false"
            >
              Cancel
            </div>
            <div
              class="confirmBtn clickBtn_S flex2 display alignCenter justifyCenter"
              @click="confirmBuy()"
            >
              Confirm Invest
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model="showPop2"
      position="top"
      :style="{ background: 'none' }"
    >
      <div class="promptMain et-02s radius15PX" style="background: none">
        <div class="promptTitleBar display alignCenter justifyDetween"></div>
        <div class="promptCont">
          <div class="topView" style="display: flex; justify-content: center">
            <img src="../images/APP/02.png" alt="" />
          </div>
          <div class="downloadAPP radius001515PX" style="background: #fff">
            <div class="explain flex1">
              <h3>Download APP</h3>
              <p>
                For the safety of your funds,<br />please try downloading the
                app and logging into the platform
              </p>
            </div>
            <div
              class="actionBar display alignCenter alignStretch"
              style="padding: 20px"
            >
              <div
                class="cancelBtn clickBtn_S flex2 radius500PX display alignCenter justifyCenter"
                @click="showPop2=false"
              >
                Cancel
              </div>
              <div
                class="downloadBtn clickBtn_S flex4 radius500PX display alignCenter justifyCenter"
                @click="goDownLoad()"
              >
                <i class="iconfont icon-arrow-down"></i>
                <p>Download Start</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model="showPop3"
      position="top"
      @closed="closePop3"
      :closeable="true"
      :style="{ width: '90%', left: '5%', top: '20%', borderRadius: '10px' }"
    >
      <div class="noticeBox">
        <h2>{{ noticeInfo.title }}</h2>
        <div v-html="noticeInfo.content"></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {
      showPop: false,
      showPop2: false,
      showPop3: false,
      tab: 0,
      dataList: [],
      type: 2,
      info: {},
      noticeInfo: {},
    };
  },
  components: {},
  methods: {
    closePop3() {
      this.showPop3 = false;
      setTimeout(() => {
        this.showPop2 = true;
      }, 500);
    },
    goDownLoad() {
      window.open("https://zdtsa33.com/app/html/index.html", "_self");
    },
    changeTab(tab) {
      this.tab = tab;
      this.type = this.tab === 0 ? 2 : this.tab === 1 ? 1 : 3;
      this.initPage();
    },
    goPage(page) {
      this.$router.push(page);
    },
    shouyi(item) {
      return item.profit_per > 0
        ? ((item.profit_per / 100) * item.price).toFixed(1)
        : item.profit;
    },
    shouyiAll(item) {
      return (
        (item.profit_per > 0
          ? ((item.profit_per / 100) * item.price).toFixed(3)
          : item.profit) * item.period
      ).toFixed(1);
    },
    buyNow(item) {
      this.showPop = true;
      this.info = item;
    },
    confirmBuy() {
      this.getAjax(
        "api/device/invest",
        {
          id: this.info.id,
          uid: this.getItem("uid"),
          type: "credits",
        },
        "post"
      ).then((res) => {
        this.showPop = false;
        if (res.code === 200) {
          this.tips("success");
        } else {
          this.tips(res.msg, "danger");
        }
      });
    },
    initPage() {
      this.getAjax(
        "api/index",
        {
          type: 2,
          uuid: this.getItem("uid"),
        },
        "post"
      ).then((res) => {
        this.dataList = res.data.device;
      });
    },
  },
  mounted() {
    if (!this.getItem("showPop2")) {
      this.getAjax("api/login/disclaimer", {}, "post").then((res) => {
        this.noticeInfo = res.data;
        this.showPop3 = true;
        this.setItem("showPop2", true);
      });
    }
    this.initPage();
    this.timer = setInterval(() => {
      this.initPage();
    }, 5000);
    var tagNavStamp = sessionStorage.getItem("tagNavStamp_Home");
    var tagNavIndex = tagNavStamp ? tagNavStamp : 0;
    $("#tagNavBar .tagNav-list>div")
      .eq(tagNavIndex)
      .addClass("selected")
      .siblings()
      .removeClass("selected");
    $("#typeList>div")
      .eq(tagNavIndex)
      .addClass("activate_list")
      .siblings()
      .removeClass("activate_list");
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped lang="less">
.noticeBox {
  padding: 15px;
  box-sizing: border-box;
  h2 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 18px;
  }
}
.homePage .logoBar {
  padding: 30px 20px 20px;
  top: 0;
  left: 0;
  position: absolute;
  background-color: var(--b_1);
  border-radius: 0 0 30px 0;
  -webkit-border-radius: 0 0 30px 0;
  -moz-border-radius: 0 0 30px 0;
  -webkit-backdrop-filter: blur(5px);
  -ms-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  z-index: 99;
}

.homePage .logoBar .logo img {
  height: 30px;
}

.homePage .banner {
  max-height: 320px;
  overflow: initial;
  position: relative;
  z-index: 0;
}

.homePage .banner > div {
  max-height: 480px;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
}

.homePage .banner .owl-theme .owl-nav.disabled + .owl-dots {
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.homePage .slogan {
  margin: 20px 0;
}

.homePage .slogan h3 {
  min-height: 88px;
  line-height: 1;
  font-size: 24px;
  font-weight: 300;
  color: #fff;
}

.homePage .balance h3 {
  font-size: 20px;
}

.homePage .balanceCard {
  margin: 0;
}

.homePage .balanceCard .clickBtn_S {
  height: 30px;
}

.homePage .kongArea {
  position: relative;
  z-index: 1;
}

.homePage .gongGeNAV li {
  margin: 10px 10px 0;
  padding: 20px 10px;
  background: #fff;
  background: linear-gradient(
    180deg,
    var(--a_5) 10%,
    rgba(255, 255, 255, 0.9) 100%
  );
  -moz-box-shadow: 0 0 20px 0px rgba(0, 40, 14, 0.1);
  -webkit-box-shadow: 0 0 20px 0px rgba(0, 40, 14, 0.1);
  box-shadow: 0 0 20px 0px rgba(0, 40, 14, 0.1);
  -webkit-backdrop-filter: blur(5px);
  -ms-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.homePage .gongGeNAV li .icon {
  width: 60px;
  max-width: 60px;
}

.homePage .gongGeNAV li p {
  font-weight: 700;
  font-size: 18px;
}

.homePage .ivestList {
  padding: 1px 0 0;
  position: relative;
}

.homePage .financialList {
  margin: 0;
  padding: 0 10px 10px;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  -ms-backdrop-filter: blur(2px);
  position: relative;
  z-index: 9;
}

.homePage .ivestList .typeList {
  margin: 70px 0 0;
}

.homePage .ivestList .typeList .productList {
  width: 100%;
  min-height: 360px;
  padding: 10px;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  display: none;
  background: #fff;
  background: linear-gradient(180deg, #fff 10%, rgba(255, 255, 255, 0) 100%);
  position: relative;
  z-index: 2;
}

/* 
.homePage .ivestList .typeList .productList#typeList2 {
    background: var(--b_2);
    background: linear-gradient(180deg, var(--b_2) 10%, rgba(255, 255, 255, 0) 100%);

}

.homePage .ivestList .typeList .productList#typeList3 {
    background: var(--a_3);
    background: linear-gradient(180deg, var(--a_3) 10%, rgba(255, 255, 255, 0) 100%);

} */

.homePage #typeList .activate_list {
  display: block;
}

.homePage .financialList ul > li {
  margin: 10px 0;
  padding: 0 10px 0 0;
  border-width: 0;
  border-color: #fff;
  border-style: solid;
  background: #fff;
  background: linear-gradient(
    180deg,
    var(--a_5) 10%,
    rgba(255, 255, 255, 1) 100%
  );
  -moz-box-shadow: 0 0 20px 0px rgba(0, 40, 14, 0.1);
  -webkit-box-shadow: 0 0 20px 0px rgba(0, 40, 14, 0.1);
  box-shadow: 0 0 20px 0px rgba(0, 40, 14, 0.1);
  overflow: hidden;
}

.homePage .financialList ul > li.skeleton {
  padding: 0;
  background: #fff;
}

.homePage .financialList ul > li .sketch {
  width: 40%;
  max-width: 140px;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
}

.homePage .financialList ul > li .eachPrice {
  padding: 20px 0;
}

.homePage .financialList ul > li .marked {
  text-align: center;
}

.homePage .financialList ul > li .marked h3 {
  font-size: 40px;
  font-weight: 700;
  color: var(--black1);
}

.homePage .financialList ul > li .marked span {
  font-size: 18px;
  color: var(--black2);
}

.homePage .financialList ul > li .buyStatusBtn {
  margin: 10px 0;
  position: relative;
  background-color: var(--a_1);
}

/* .homePage .financialList #typeList2 ul>li .buyStatusBtn {
    background-color: var(--b_2);
} */

.homePage .financialList ul > li .buyStatusBtn .requiredLV {
  margin: 0 10px 0 0;
}

.homePage .financialList ul > li .buyStatusBtn p {
  font-weight: 700;
}

.homePage .financialList ul > li .buyStatusBtn img.upcastArrow {
  height: 50px;
  right: -30px;
  bottom: -10px;
  position: absolute;
}

.homePage .financialList ul > li .attribute {
  padding: 10px 0;
}

.homePage .financialList ul > li .attribute ol li {
  padding: 5px;
}

.homePage .financialList ul > li .attribute .title {
  min-width: 100px;
}

.homePage .financialList ul > li .attribute .title span {
  font-size: 16px;
  color: var(--black2);
}

.homePage .financialList ul > li .attribute .item p {
  font-size: 20px;
  font-weight: 700;
  color: var(--black1);
}
.mui-bar {
  position: fixed;
  z-index: 10;
  right: 0;
  left: 0;
  border-bottom: 0;
}

.tagNavBar {
  margin: 0;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  z-index: 1;
}

.tagNav-list {
  overflow: hidden;
  overflow-x: scroll;
}

.tagNav {
  width: auto !important;
  z-index: 0;
  position: relative;
}

.tagNav:last-child {
  margin: 0;
}

.tagNav > div {
  margin: 20px 5px 0 0;
  padding: 10px 30px 45px;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  position: relative;
  background: #b8b8b8;
  background: linear-gradient(0deg, #cfcfcf 29%, #b8b8b8 100%);
  -webkit-transition: all 0.4s ease-in;
  -ms-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  z-index: 2;
}

.tagNav > div p:after {
  content: " ";
  width: 30%;
  height: 5px;
  content: "";
  bottom: -6px;
  left: 0;
  right: 0;
  position: absolute;
  background-color: #fff;
  opacity: 0;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
}

.tagNav > div:last-child:after {
  width: 0;
}

.tagNav p {
  color: #fff;
  font-size: 18px;
  position: relative;
  z-index: 1;
}

.tagNav i {
  right: 0;
  bottom: 0;
  font-size: 36px;
  color: var(--a_3);
  position: absolute;
  opacity: 0.3;
  z-index: 0;
}

.tagNav.selected > div {
  background: #fff;
  /* background: linear-gradient(0deg, var(--a_2) 29%, var(--a_1) 100%); */
  -moz-box-shadow: 0 -10px 10px 0 rgba(0, 40, 14, 0.1);
  -webkit-box-shadow: 0 -10px 10px 0 rgba(0, 40, 14, 0.1);
  box-shadow: 0 -10px 10px 0 rgba(0, 40, 14, 0.1);
  z-index: 3;
}

.tagNav.selected > div p {
  color: var(--a_1);
}

.tagNav.selected > div p:after {
  background: #fff;
  opacity: 1;
}

/* .tagNav.selected.tag_2>div {
  background: var(--b_2);
  background: linear-gradient(0deg, var(--b_2) 29%, var(--b_1) 100%);
}

.tagNav.selected.tag_3>div {
  background: var(--a_3);
  background: linear-gradient(0deg, var(--a_3) 29%, var(--a_2) 100%);
} */

.tagNav.selected p {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  position: relative;
}

.tagNav.selected p .icon {
  margin: 0 5px 0 0;
  font-size: 22px;
}

#tagNavBar .tagNav-list::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
</style>
